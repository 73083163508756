.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar Styles */
.navbar {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(10px);
  transition: background-color 0.3s ease;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1030;
  padding: 1rem 0;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.navbar .nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
  transition: color 0.3s ease;
}

.navbar .nav-link:hover,
.navbar .nav-link.active {
  color: #ffffff !important;
}

.navbar-brand {
  color: #ffffff !important;
  font-weight: 600;
}

/* Add padding to body to prevent content from being hidden under navbar */
body {
  padding-top: 0;
}

/* Hero Section */
.hero {
  min-height: 130vh;
  background-color: #000;
  color: white;
  margin-top: -76px;
  padding-top: 96px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.hero-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 64%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.hero-bg-image {
  width: 70%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 60%;
  left: 45%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, visibility 1.5s ease-in-out;
}

.hero-bg-image.active {
  opacity: 1;
  visibility: visible;
}

.hero .container {
  position: relative;
  z-index: 2;
  margin-top: 5vh;
}

.hero-text {
  padding: 2rem;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}

.hero-text h1 {
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  background: linear-gradient(45deg, #ff0844 0%, #ffb199 35%, #8b2c8b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  position: relative;
  display: inline-block;
}

.hero-text h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #ff0844, #8b2c8b);
  border-radius: 2px;
}

.hero-text p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

@media (max-width: 991.98px) {
  .hero {
    text-align: center;
    padding-top: 76px;
    min-height: 100vh;
  }
  
  .hero-background {
    width: 80%;
  }
  
  .hero-text {
    margin: 0 auto;
    max-width: 90%;
  }
  
  .hero-text h1 {
    font-size: 2.5rem;
  }
  
  .hero-text h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* General Styles */
.section-header {
  margin-bottom: 3rem;
}

.section-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: 0.4}
  to {opacity: 1}
}

/* Services Preview */
.service-card {
  text-align: center;
  padding: 2rem;
  border-radius: 10px;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 100%;
}

/* VIP Protection Card Special Styling */
.services-preview .service-card:has(.fa-user-shield) {
  background: linear-gradient(145deg, #1a1a1a, #2d2d2d);
  border: 2px solid #ffd700;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2),
              inset 0 0 20px rgba(255, 215, 0, 0.1);
}

.services-preview .service-card:has(.fa-user-shield) .service-icon {
  background: linear-gradient(145deg, #ffd700, #b8860b);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.services-preview .service-card:has(.fa-user-shield) h3 {
  color: #ffd700;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.services-preview .service-card:has(.fa-user-shield) p {
  color: #e0e0e0;
}

.services-preview .service-card:has(.fa-user-shield) .btn-outline-primary {
  color: #ffd700;
  border-color: #ffd700;
  background: transparent;
  transition: all 0.3s ease;
}

.services-preview .service-card:has(.fa-user-shield) .btn-outline-primary:hover {
  background: #ffd700;
  color: #1a1a1a;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
}

.services-preview .service-card:has(.fa-user-shield):hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3),
              0 0 30px rgba(255, 215, 0, 0.1);
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 3rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.service-card h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

/* About Preview */
.about-preview {
  background-color: var(--light-gray);
}

.about-preview h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.about-preview .lead {
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.about-preview p {
  color: var(--text-color);
  margin-bottom: 2rem;
  line-height: 1.8;
}

.about-image img {
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-image img:hover {
  transform: scale(1.02);
}

/* Team Preview */
.team-member {
  margin-bottom: 2rem;
}

.member-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 5px solid #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Clients Section */
.clients-section {
  background-color: var(--light-gray);
  position: relative;
  overflow: hidden;
}

.clients-carousel {
  position: relative;
  padding: 2rem 0;
}

.client-list {
  transition: transform 0.3s ease-in-out;
  margin: 0 3rem;
}

.client-item {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  margin: 0.5rem;
}

.client-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.client-item h3 {
  color: var(--primary-color);
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.client-item p {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  pointer-events: none;
  z-index: 2;
}

.carousel-controls button {
  background: transparent;
  border: none;
  cursor: pointer;
  pointer-events: auto;
  padding: 0.5rem;
  transition: transform 0.3s ease;
  color: var(--accent-color);
  font-size: 2rem;
}

.carousel-controls button:hover {
  transform: scale(1.2);
  color: var(--secondary-color);
}

.carousel-controls svg {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .client-list {
    margin: 0 2rem;
  }
  
  .carousel-controls {
    padding: 0;
  }
  
  .client-item {
    margin: 0.25rem;
  }
}

/* Contact Form */
.contact-form {
  background-color: #f8f9fa;
}

.contact-form form {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.contact-form .form-control {
  padding: 0.75rem 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.contact-form .form-control:focus {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 0.2rem rgba(13, 71, 161, 0.25);
}

.contact-form textarea.form-control {
  resize: vertical;
  min-height: 120px;
}

.contact-form .btn-primary {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #222;
  border-color: #222;
  transition: all 0.3s ease;
}

.contact-form .btn-primary:hover {
  background-color: #444;
  border-color: #444;
  transform: translateY(-2px);
}

/* Footer */
footer {
  margin-top: 2rem;
}

footer h5 {
  margin-bottom: 1.5rem;
}

footer ul li {
  margin-bottom: 0.5rem;
}

.social-links a {
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #007bff !important;
}

/* Page Styles */
.page-content {
  padding-top: 0; /* Height of navbar */
}

.page-header {
  background-color: #007bff;
  color: white;
  margin-bottom: 2rem;
}

/* About Page */
.feature-box {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.feature-box:hover {
  transform: translateY(-5px);
}

.feature-list {
  list-style: none;
  padding-left: 0;
}

.feature-list li {
  margin-bottom: 1rem;
}

/* Services Page */
.service-section {
  padding: 2rem 0;
  border-bottom: 1px solid #dee2e6;
}

.service-section:last-child {
  border-bottom: none;
}

.service-image-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: none;
}

.feature-item {
  font-size: 1.1rem;
}

/* Team Page */
.team-page .page-header {
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}

.team-page .page-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.team-page .page-header .lead {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
}

/* Leadership Team */
.leadership-team {
  padding: 80px 0;
}

.team-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  transition: transform 0.3s ease;
}

.team-card:hover {
  transform: translateY(-10px);
}

.team-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.team-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-info {
  padding: 2rem;
}

.team-info h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.team-info .position {
  color: var(--accent-color);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.team-info .contact-info {
  margin-bottom: 1rem;
}

.team-info .contact-info p {
  color: #666;
  margin-bottom: 0.5rem;
}

.team-info .contact-info svg {
  color: var(--accent-color);
  margin-right: 10px;
}

.team-info .bio {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

/* Strategic Team */
.strategic-team {
  background-color: #f8f9fa;
}

.set-team-image {
  position: relative;
}

.set-team-image img {
  width: 100%;
  border-radius: 15px;
}

.team-description {
  color: #666;
  line-height: 1.6;
}

.team-description ul li {
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
}

.team-description ul li svg {
  position: absolute;
  left: 0;
  top: 4px;
}

/* Team Stats */
.team-stats {
  background: #1a1a1a;
  color: white;
  padding: 60px 0;
}

.stat-item {
  padding: 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.1);
}

.stat-item h3 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #ffd700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.stat-item p {
  font-size: 1.2rem;
  margin: 0;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .team-page .page-header {
    padding: 80px 0 60px;
  }

  .team-page .page-header h1 {
    font-size: 2.5rem;
  }

  .team-page .page-header .lead {
    font-size: 1.2rem;
  }

  .leadership-team {
    padding: 60px 0;
  }

  .team-card {
    margin-bottom: 30px;
  }

  .team-info h3 {
    font-size: 1.5rem;
  }

  .team-info .position {
    font-size: 1.1rem;
  }

  .stat-item h3 {
    font-size: 2.5rem;
  }

  .stat-item p {
    font-size: 1rem;
  }
}

/* Statistics Section */
.statistics {
  background-color: var(--primary-color);
  position: relative;
}

.statistics::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.stat-item {
  position: relative;
  padding: 2rem;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-10px);
}

.stat-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.stat-item h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 1rem 0;
  background: linear-gradient(45deg, #ff0844 0%, #ffb199 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-item p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .page-content {
    padding-top: 0;
  }

  .team-image-wrapper {
    height: 200px;
  }
}

/* About Page Styles */
.about-page .page-header {
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}

.about-page .page-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.about-page .page-header .lead {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
}

.company-overview {
  padding: 80px 0;
}

.company-overview h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.company-overview .lead {
  font-size: 1.25rem;
  color: var(--accent-color);
  margin-bottom: 2rem;
}

.company-overview p {
  color: #666;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.company-overview ul li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.company-overview .fa-check-circle {
  color: var(--accent-color);
  margin-right: 10px;
}

.mission-vision {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.mission-card {
  background: white;
  padding: 3rem 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease;
}

.mission-card:hover {
  transform: translateY(-10px);
}

.mission-card svg {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

.mission-card h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.core-values {
  padding: 80px 0;
}

.core-values h2 {
  text-align: center;
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 600;
}

.value-card {
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-10px);
}

.value-card svg {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

.value-card h4 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.why-choose-us {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.why-choose-us h2 {
  text-align: center;
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 600;
}

.choose-card {
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease;
}

.choose-card:hover {
  transform: translateY(-10px);
}

.choose-card svg {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

.choose-card h4 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Call to Action Section */
.cta-section {
  color: white;
  text-align: center;
  padding: 80px 0;
}

.cta-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.cta-section .lead {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.cta-section .btn-primary {
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .client-type-card {
    margin-bottom: 30px;
  }

  .client-type-card h3 {
    font-size: 1.5rem;
  }

  .cta-section h2 {
    font-size: 2rem;
  }

  .cta-section .lead {
    font-size: 1.2rem;
  }
}

/* Services Page Styles */
.services-page .page-header {
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}

.services-page .page-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.services-page .page-header .lead {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
}

.services-grid {
  padding: 80px 0;
}

.service-category h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.service-card {
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

.service-card h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.service-card p {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.service-features {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.service-features li {
  margin-bottom: 0.8rem;
  color: #555;
  font-size: 1.1rem;
}

.service-features li svg {
  color: var(--accent-color);
  margin-right: 10px;
}

.client-types {
  background-color: #f8f9fa;
}

.client-type-card {
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease;
  text-align: center;
}

.client-type-card:hover {
  transform: translateY(-10px);
}

.client-type-card .client-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

.client-type-card h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.client-type-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.client-type-card ul li {
  color: #666;
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
  padding-left: 1.5rem;
  position: relative;
}

.client-type-card ul li:before {
  content: "•";
  color: var(--accent-color);
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .client-type-card {
    margin-bottom: 30px;
  }

  .client-type-card h3 {
    font-size: 1.5rem;
  }

  .services-page .page-header {
    padding: 80px 0 60px;
  }

  .services-page .page-header h1 {
    font-size: 2.5rem;
  }

  .services-page .page-header .lead {
    font-size: 1.2rem;
  }

  .services-grid {
    padding: 60px 0;
  }

  .service-category h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .service-card,
  .client-type-card {
    margin-bottom: 30px;
  }

  .service-card h3,
  .client-type-card h3 {
    font-size: 1.5rem;
  }
}

/* Careers Page Styles */
.careers-page .page-header {
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}

.careers-page .page-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.careers-page .page-header .lead {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
}

/* Recruitment Process */
.recruitment-process {
  background-color: #f8f9fa;
}

.process-card {
  height: 100%;
  transition: transform 0.3s ease;
}

.process-card:hover {
  transform: translateY(-5px);
}

.process-card h4 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.process-card p {
  color: #666;
  line-height: 1.6;
}

.process-card ul li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

/* Training Program */
.training-program {
  background-color: #f8f9fa;
}

.course-card {
  height: 100%;
  transition: transform 0.3s ease;
}

.course-card:hover {
  transform: translateY(-5px);
}

.course-card h4 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.course-card ul li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  line-height: 1.4;
  color: #666;
}

.course-card ul li svg {
  color: var(--primary-color);
}

/* Training Process */
.training-process {
  background-color: white;
}

.process-description {
  height: 100%;
}

.process-description h3 {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.process-description p {
  color: #666;
  line-height: 1.6;
  font-size: 1.1rem;
}

.process-description ul li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #666;
}

.process-description ul li svg {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .careers-page .page-header {
    padding: 80px 0 60px;
  }

  .careers-page .page-header h1 {
    font-size: 2.5rem;
  }

  .careers-page .page-header .lead {
    font-size: 1.2rem;
  }

  .process-card h4,
  .course-card h4,
  .process-description h3 {
    font-size: 1.5rem;
  }

  .process-card ul li,
  .course-card ul li,
  .process-description ul li,
  .process-description p {
    font-size: 1rem;
  }
}

/* Contact Page Styles */
.contact-page .page-header {
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
  margin-top: -76px;
}

.contact-page .page-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
}

.contact-page .page-header .lead {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

/* Contact Info Cards */
.contact-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 30px;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
}

.contact-card svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.contact-card h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.contact-card p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

/* Contact Form */
.contact-form {
  background-color: #f8f9fa;
  padding: 80px 0;
}

.contact-form .card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.contact-form label {
  color: #444;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.contact-form .form-control,
.contact-form .form-select {
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.contact-form .form-control:focus,
.contact-form .form-select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
}

.contact-form textarea.form-control {
  resize: vertical;
  min-height: 120px;
}

.contact-form .btn-primary {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #222;
  border-color: #222;
  transition: all 0.3s ease;
}

.contact-form .btn-primary:hover {
  background-color: #444;
  border-color: #444;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .contact-page .page-header {
    padding: 100px 0 60px;
  }

  .contact-page .page-header h1 {
    font-size: 2.5rem;
  }

  .contact-page .page-header .lead {
    font-size: 1.2rem;
  }

  .contact-card {
    margin-bottom: 30px;
  }

  .contact-card h3 {
    font-size: 1.5rem;
  }

  .contact-card p {
    font-size: 1rem;
  }

  .contact-form {
    padding: 40px 0;
  }

  .contact-form h2 {
    font-size: 2rem;
  }

  .contact-form .card-body {
    padding: 1.5rem !important;
  }
}

/* Gallery Page Styles */
.gallery-page .page-header {
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
  margin-top: -76px;
  z-index: 1;
}

.gallery-page .page-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
}

.gallery-page .page-header .lead {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

/* Gallery Grid */
.gallery-grid {
  background-color: #f8f9fa;
  padding: 80px 0;
}

.gallery-item {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.gallery-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-overlay i {
  color: white;
  font-size: 2rem;
}

/* Lightbox customization */
.ril__outer {
  background-color: rgba(0, 0, 0, 0.85) !important;
  z-index: 9999 !important;
}

.ril__toolbar {
  background-color: transparent !important;
  z-index: 10000 !important;
}

.ril__toolbarItem {
  padding: 0 10px;
  color: #fff;
}

.ril__navButtons {
  z-index: 10000 !important;
}

@media (max-width: 768px) {
  .gallery-page .page-header {
    padding: 100px 0 60px;
  }

  .gallery-page .page-header h1 {
    font-size: 2.5rem;
  }

  .gallery-page .page-header .lead {
    font-size: 1.2rem;
  }

  .gallery-grid {
    padding: 40px 0;
  }

  .gallery-item img {
    height: 200px;
  }
}

/* Admin Dashboard Styles */
.admin-dashboard {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 2rem;
}

.admin-dashboard header {
  margin-top: -76px;
}

.dashboard-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.card-icon {
  width: 48px;
  height: 48px;
  background-color: #f8f9fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

/* Admin Icon in Navbar */
.admin-icon {
  color: #888888;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  text-decoration: none;
  position: absolute;
  right: 15px;
  top: -12px;
  z-index: 1100;
}

.admin-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #a0a0a0;
}

/* Admin Dashboard Home Link */
.admin-dashboard .home-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.admin-dashboard .home-link:hover {
  opacity: 0.8;
  color: white;
}

.admin-dashboard .home-link span {
  font-size: 1rem;
  font-weight: 500;
}

/* Banner Section */
.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 140px 0 80px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  margin-top: -76px;  /* Pull banner up to fill the space */
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.banner-content {
  position: relative;
  z-index: 1;
}

.banner h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
